/*
Mini-Cards

Markup:

Styleguide 3.2.1

*/

.mini-card {

  border: 1px solid $offWhite;
  border-radius: 0 0 5px 5px;
  margin-bottom: 20px;


  &__header {
    position: relative;
    padding: 15px 20px;
    border-radius: 5px 5px 0 0;

    p {
      color: $white;
      margin-bottom: 0;
    }
  }

  &__icon {
    position: absolute;
    right: 20px;
    bottom: -20px;
    background-color: $white;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      position: relative;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }

  &__content {
    padding: 20px;
  }

  &__logo {
    height: 25px;
    margin-bottom: 10px;
    img {
      display: inherit;
      height: 100%;
    }
  }

  &__helper {
    margin-bottom: 12px;
  }

  .link-primary {
    cursor: pointer;
    position: relative;

    span {
      @include type(11px,19px);
      vertical-align: 2px;
      margin-left: 7px;
      position: absolute;
      top: 0px;
    }
  }

  // Color modifier
  &--teal {
    .mini-card__header { background-color: $teal; }
  }
  &--purple {
    .mini-card__header { background-color: #784D7F; }
  }
  &--pink {
    .mini-card__header { background-color: #D52953; }
  }
  &--green {
    .mini-card__header { background-color: $green; }
  }

}
