/*
Navigation

Markup:

Styleguide 3.0.

*/

.mobile-container {
  @include desktop {
    background-color: transparent;
  }
}


.primary-navigation {

  @include desktop {

    position: relative;
    z-index: 1;
    padding-bottom: 0;

    &__link {
      padding-bottom: 26px;
      position: relative;

      // Hover underline
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 24px;
        width: 100%;
        height: 3px;
        background-color: transparent;
        transition: all .3s ease;
      }


      &:hover,
      &:focus {
        border-bottom-color: transparent;

        &:before {
          background-color: $orange;
        }
      }

      &--products {
        position: relative;
        margin-right: 23px;

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: 34px;
          right: -11px;
          border-style: solid;
          border-width: 5px 4px 0 4px;
          border-color: transparent;
          border-top-color: $greyDark;
          transition: all .3s ease;
        }
      }

    }

    .sub-on & {
      .primary-navigation__link--products {
        color: $orange;
        &:before {
          background-color: $orange;
        }
        &:after {
          border-top-color: $orange;
        }
      }
    }

  }

}

.utility-navigation {
  @include desktop {
    z-index: 1;
  }
}


.product-navigation {

  display: none;

  @include desktop {

    display: block;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 85%;
    transition: all .3s ease;

    &__container {
      @include flexbox;
      background-color: $white;
    }

    &__product {
      display: inline-block;
      border-right: 1px solid $greyLightBG;
      border-bottom: 1px solid $greyLightBG;
      cursor: pointer;

      h4 {
        color: $greyDark;
        margin-bottom: 0;
        transition: all .3s ease;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: $greyLightBG;

        h4 {
          color: $orange;
        }
        .product-navigation__icon {
          background-color: $orange;

          svg {
            fill: $white;
          }
        }
      }

      &--all {
        position: relative;
        padding: 0;

        .product-navigation__product-content {
          padding: 0 30px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          a {
            display: block;
            font-size: 14px;
            margin-top: 8px;
          }
        }

        &:hover,
        &:focus {
          h4 {
            color: $greyDark;
          }
        }
      }
    }

    &__product-content {
      padding: 35px 0;
    }

    // Product icons
    &__icon {
      display: inline-block;
      width: 62px;
      height: 62px;
      margin-bottom: 11px;
      padding-top: 9px;
      border-radius: 50%;
      background-color: $greyLight3;
      transition: all .3s ease;

      svg {
        width: 42px;
        height: 42px;
        transition: all .3s ease;
      }
    }

    // Technology lists
    &__tech {
      min-width: 200px;
      padding: 25px 30px;
      background-color: $greyDark2;
      color: $white;

      h4 {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
      }

      ul {
        padding: 0;

        li {
          list-style: none;
          font-size: 10px;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-bottom: 15px;

          a {
            cursor: pointer;
            color: $white;

            &:hover,
            &:focus {
              color: $orange;
            }
          }
        }
      }
    }


    .sub-on & {
      visibility: visible;
      opacity: 1;
      top: 100%;
    }

  }
}
