

.checkmark{
  display:inline-block;

  &:after{
    /*Add another block-level blank space*/
    content: '';
    display: block;

    /*Make it a small rectangle so the border will create an L-shape*/
    width: 7px;
    height: 14px;

    /*Add a white border on the bottom and left, creating that 'L' */
    border: solid #000;
    border-width: 0 3px 3px 0;

    /*Rotate the L 45 degrees to turn it into a checkmark*/
    transform: rotate(45deg);
  }

  &--green {
    &:after { border-color: $green; }
  }

  &--left {
    margin-right: 12px;
  }

  &--box {
    width: 20px;
    height: 20px;
    border: 2px solid $offWhite;
    vertical-align: -3px;
    position: relative;

    &:after {
      position: absolute;
      top: -1px;
      left: 5px;
    }
  }
}
