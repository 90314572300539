@mixin font($family: proxima, $weight:regular) {
	//Presets
	$font-family: arial,sans-serif;
	$font-weight: normal;
	$font-style: normal;

	//Weight
	@if $weight == semibold {
		$font-weight: 600;
	}
	@else if $weight == regular {
		$font-weight: 400;
	}
	@else if $weight == light {
		$font-weight: 300;
	}

	//Family
	@if $family == proxima {
		$font-family: $proxima;
	}

	@if $family == hurme {
		$font-family: $hurmeregular;
		$font-weight: 400;
	}

  //WE DONT HAVE THIS FONT
	@if $family == hurmelight {
		$font-family: $hurmeregular;
		$font-weight: 400;
	}

	@if $family == hurmesemibold {
		$font-family: $hurmesemibold;
		$font-weight: 600;
	}

	font-family: $font-family;
	font-style:  $font-style;
	font-weight: $font-weight;
}

@mixin type($font-size, $line-height: false, $letter-spacing: false) {
	font-size: $font-size;
	@if ($line-height) {
		line-height: $line-height;
	}
	@if ($letter-spacing) {
		letter-spacing: $letter-spacing;
	}
}

@mixin transition($type: all, $time: .3s, $ease: ease) {
	transition: $type $time $ease;
}
@mixin mobile-specific {
	@media screen and (max-width: #{$mqSmMax}) {
		@content
	}
}

@mixin tablet-down {
  @media screen and (max-width: #{$mqMdMax}) {
    @content;
  }
}

@mixin small {
  @media screen and (min-width: #{$mqXs}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$mqSm}) {
    @content;
  }
}
@mixin tablet-specific {
  @media screen and (min-width: #{$mqSm}) and (max-width: #{$mqLgMax}) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: #{$mqMd}) {
    @content;
  }
}
@mixin desktop-lg-down {
  @media screen and (max-width: #{$mqLgMax}) {
    @content;
  }
}
@mixin desktop-lg {
  @media screen and (min-width: #{$mqLg}) {
    @content;
  }
}

@mixin background-color($color:#F7F7F7) {
	background-color: $color;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}
@mixin vertical-horizontal-align($position: absolute) {
	position: $position;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}
@mixin col-fluid($cols) {
	padding: 0 20px;
	@media (min-width: #{$mqSm}) {
		width: $mqSm;
		margin: 0 auto;
	}
	@media (min-width: #{$mqMd}) {
	    width: $mqMd * $cols/12;
	}
	@media (min-width: #{$mqLg}) {
	    width: $mqLg * $cols/12;
	}
}
@mixin col-fluid-container($maxwidth, $cols) {
	width: calc((100vw - #{$maxwidth})/2 + (#{$maxwidth} * #{$cols}/12));
}



// Flexbox shorthand
@mixin flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

// Flex shorthand
@mixin flex($values: auto) {
	-webkit-box-flex: $values;
	-moz-box-flex:  $values;
	-webkit-flex:  $values;
	-ms-flex:  $values;
	flex:  $values;
}
