

.row {
  @include desktop {
    &--half-bottom-pad {
      padding-bottom: 45px;
    }
    &--half-top-pad {
      padding-top: 45px;
    }
  }
}
