/*
Feature

Markup:

Styleguide 3.2.1

*/

.feature {

    &__navigation-card-container {
      display: inline-block;
      // height: 250px;
      // margin: 0 auto -125px;

      @include desktop {
        height: 250px;
        margin: 0 auto -125px;
      }
    }

    .navigation-card {
      display: inline-block;
      // float: left;
      vertical-align: top;
      width: 170px;
      min-height: 200px;
      position: relative;
      top: 35px;
      background-color: $white;
      padding: 0 10px 10px;
      margin: 0 5px 30px;
      box-shadow: 0 2px 14px 5px rgba(0,0,0,.12);
      cursor: pointer;
      text-decoration: none;
      transition: all .25s ease;

      @include desktop {
        float: left;
        margin: 0 5px;
      }

      &__icon {
        display: inline-block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: $greyLight3;
        margin: -20px 0 10px;
        transition: all .4s ease;
        padding: 20px;

        svg {
          width: 40px;
          height: 40px;
          transition: all .3s ease;
        }
      }

      &__name {
        @include type(18px,22px);
        color: $greyDark;
        margin-bottom: 12px;
      }

      &__helper {
        @include type(16px,21px);
        color: $greyLight2;
        margin-bottom: 10px;
      }

      &__link {
        visibility: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        color: $orange;
        transform: translate3d(0, 10px, 0);
        transition: transform .3s ease .15s, opacity .3s ease .15s;

        span {
          @include type(11px,19px);
          vertical-align: 2px;
          margin-left: 7px;
        }
      }

      &:hover,
      &:focus {
        top: 10px;
        min-height: 250px;

        .navigation-card__icon {
          background-color: $orange;

          svg {
            fill: $white;
          }
        }

        .navigation-card__link {
          visibility: visible;
          opacity: 1;
          bottom: 20px;
          transform: translate3d(0, 0, 0);
        }
      }
    }


    // Modifier
    &--index {

      @include desktop {
        height: 500px;
        margin-bottom: 85px;

        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8 {
          height: 250px;
        }
      }

      .feature__img {
        -webkit-filter: grayscale(100%) brightness(.7);
        filter: grayscale(100%) brightness(.7);
      }
    }

    &--in-page {
      background-color: $white;

      .feature__navigation-card-container {
        padding-bottom: 10px;
        box-sizing: content-box;
        
        @include desktop {
          padding-bottom: 125px;
        }
      }
    }

    &--black-overlay {

      &:before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        left: 0;
        background: rgba(0,0,0,.4);
        z-index: 0;
      }
    }

    &--features {
      padding-top: 30px;
      padding-bottom: 30px;

      ul {
        list-style-type: none;
        padding: 0;

        li {
          display: inline-block;
          padding: 0 20px;
        }
      }
    }

}

.scrollnav-container {
  display: none;
}
