/*
Product-Card

Markup:

Styleguide 3.2.1

*/

.product-card {

  display: inline-block;
  width: 100%;
  padding: 14px 20px;
  // margin: 0 4.5% 20px 0;
  margin-bottom: 20px;
  box-shadow: 0 2px 14px 5px rgba(0,0,0,.05);

  @include small {
    max-width: 45%;
    margin: 0 2.5% 20px 0;

    &:nth-child(2n+1) {
      margin-right: 0;
    }
  }

  @include desktop {
    max-width: 240px;
    margin-bottom: 20px;
  }

  &__icon {
    display: table-cell;
    vertical-align: middle;

    span {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding-top: 7px;
      background-color: $orange;
      border-radius: 50%;

      svg {
        width: 36px;
        height: 36px;
        fill: $white;
      }
    }
  }

  &__name {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
    color: $greyDark;
    @include type(16px,21px);
  }

}
